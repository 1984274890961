import { Fragment, PureComponent } from 'react';

import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ErrorMessage from 'components/Shared/ErrorMessage';
import formatDistance from 'date-fns/formatDistance';
import locale from 'date-fns/locale/pt-BR';
import { IStyledProps, WithStyles } from 'decorators/withStyles';
import { dateFormat } from 'formatters/date';
import ISync, { IDashboardSync } from 'interfaces/models/sync';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import UpdateIcon from 'mdi-react/UpdateIcon';
import bindComponent from 'rxjs-operators/bindComponent';
import dashboardService from 'services/dashbord';

interface IState {
  sync?: IDashboardSync;
  loading: boolean;
  error?: any;
}

@WithStyles(theme => ({
  wrapper: {
    height: '100%'
  },
  icon: {
    margin: '0 10px 0 0',
    verticalAlign: 'text-top'
  },
  iconError: {
    color: theme.palette.error.main
  },
  text: {
    lineHeight: 'normal'
  },
  syncWrapper: {
    minWidth: 200
  }
}))
export default class Sync extends PureComponent<IStyledProps, IState> {
  constructor(props: IStyledProps) {
    super(props);
    this.state = { loading: true };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState({ loading: true, error: null });

    dashboardService
      .sync()
      .pipe(bindComponent(this))
      .subscribe({
        next: sync => this.setState({ loading: false, sync }),
        error: error => this.setState({ loading: false, error })
      });
  };

  render() {
    const { error, sync } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.wrapper}>
        {!!error && (
          <CardContent>
            <ErrorMessage error={error} tryAgain={this.loadData} />
          </CardContent>
        )}

        {!error && (
          <CardContent>
            <Grid container spacing={2}>
              <Grid item sm={false}>
                <SyncData title='Viva Real' sync={(sync || ({} as any)).vivareal} classes={classes} />
              </Grid>
              <Grid item sm={false}>
                <SyncData title='Olx' sync={(sync || ({} as any)).olx} classes={classes} />
              </Grid>
              <Grid item sm={false}>
                <SyncData title='Imóvel Web' sync={(sync || ({} as any)).imovelweb} classes={classes} />
              </Grid>
              <Grid item sm={false}>
                <SyncData title='Chaves na Mão' sync={(sync || ({} as any)).chavesnamao} classes={classes} />
              </Grid>
              <Grid item sm={false}>
                <SyncData title='Lead4 Sales' sync={(sync || ({} as any)).lead4sales} classes={classes} />
              </Grid>
            </Grid>
          </CardContent>
        )}
      </div>
    );
  }
}

function SyncData({ title, sync, classes }: { title: string; sync: ISync; classes: any }) {
  return (
    <div className={classes.syncWrapper}>
      <Typography color='textSecondary' gutterBottom>
        {title}
      </Typography>

      {!sync && (
        <Typography className={classes.text}>
          <UpdateIcon className={classes.icon} />
          Nunca
        </Typography>
      )}

      {sync && (
        <Fragment>
          <Typography variant='subtitle1' className={classes.text}>
            {sync.success ? (
              <UpdateIcon className={classes.icon} />
            ) : (
              <CloseCircleIcon className={`${classes.icon} ${classes.iconError}`} />
            )}
            {formatDistance(sync.createdDate, new Date(), { locale })}
          </Typography>
          <Typography variant='caption'>
            <span>{sync.success ? 'Sucesso  • ' : 'Falha • '}</span>
            {dateFormat(sync.createdDate, "dd/MM/yy 'às' HH:mm")}
          </Typography>
        </Fragment>
      )}
    </div>
  );
}
