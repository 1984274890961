import { PureComponent, Fragment } from 'react';

import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FieldSwitch from '@react-form-fields/material-ui/components/Switch';

import PropertyFormImovelWebCategory from './ImovelWebCategory';
import PropertyFormOlxCategory from './OlxCategory';
import PropertyFormPage from '..';

interface IState {}

interface IProps {
  disabled?: boolean;
  model: PropertyFormPage['state']['model'];
  updateModel: PropertyFormPage['updateModel'];
}

export default class PropertyFormIntegrations extends PureComponent<IProps, IState> {
  render() {
    const { model, updateModel, disabled } = this.props;

    return (
      <Fragment>
        <CardContent>
          <Typography variant='h6' gutterBottom>
            Anúncio / Integração
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <FieldSwitch
                label='Anunciar no Viva Real'
                disabled={disabled}
                checked={!model.disableSyncVivaReal}
                onChange={updateModel(m => (m.disableSyncVivaReal = !m.disableSyncVivaReal))}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FieldSwitch
                label='Anunciar no Imóvel Web'
                disabled={disabled}
                checked={!model.disableSyncImovelWeb}
                onChange={updateModel(m => (m.disableSyncImovelWeb = !m.disableSyncImovelWeb))}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FieldSwitch
                label='Anunciar no Chaves na Mão'
                disabled={disabled}
                checked={!model.disableSyncChavesNaMao}
                onChange={updateModel(m => (m.disableSyncChavesNaMao = !m.disableSyncChavesNaMao))}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FieldSwitch
                label='Anunciar no Lead4 Sales'
                disabled={disabled}
                checked={!model.disableSyncLead4Sales}
                onChange={updateModel(m => (m.disableSyncLead4Sales = !m.disableSyncLead4Sales))}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FieldSwitch
                label='Anunciar no Site IBS'
                disabled={disabled}
                checked={!model.disableSyncSiteIbs}
                onChange={updateModel(m => (m.disableSyncSiteIbs = !m.disableSyncSiteIbs))}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FieldSwitch
                label='Anunciar no Site Vision'
                disabled={disabled}
                checked={!model.disableSyncSiteVision}
                onChange={updateModel(m => (m.disableSyncSiteVision = !m.disableSyncSiteVision))}
              />
            </Grid>
          </Grid>
        </CardContent>

        <Divider />

        <CardContent>
          <PropertyFormImovelWebCategory model={model} updateModel={updateModel} disabled={disabled} />
        </CardContent>

        <Divider />
        <CardContent>
          <PropertyFormOlxCategory model={model} updateModel={updateModel} disabled={disabled} />
        </CardContent>
      </Fragment>
    );
  }
}
