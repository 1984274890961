import { IImageReaderResult } from 'components/Shared/ImageSelector/ImageReader';

import IAddress from './address';
import IPropertyExtraBenefit from './propertyExtraBenefit';
import IPropertyImovelWebCategory from './propertyImovelWebCategory';
import IPropertyLocation from './propertyLocation';
import IPropertyOlxCategory from './propertyOlxCategory';
import IPropertyOwner from './propertyOwner';
import IPropertyType from './propertyType';

export enum enRentalPeriod {
  monthly = 'Monthly',
  daily = 'Daily',
  weekly = 'Weekly',
  quarterly = 'Quarterly',
  yearly = 'Yearly'
}

export enum enTransactionType {
  sale = 'For Sale',
  rent = 'For Rent',
  both = 'Sale/Rent'
}

export enum enPropertyStatus {
  pending = 'pending',
  completed = 'completed',
  canceled = 'canceled'
}

export enum enPropertyFeaturedImovelWeb {
  simple = 'Simples',
  featured = 'Destaque',
  superFeatured = 'Home'
}

export default interface IProperty {
  id: number;
  title: string;
  transactionType: enTransactionType;
  featured: boolean;
  featuredChavesNaMao?: boolean;
  featuredImovelWeb?: enPropertyFeaturedImovelWeb;
  description: string;
  salesPrice?: number;
  rentalPrice?: number;
  rentalPeriod?: enRentalPeriod;
  propertyAdministrationFee?: number;
  yearlyTax?: number;
  lotArea?: number;
  livingArea?: number;
  bedrooms?: number;
  bathrooms?: number;
  suites?: number;
  garage?: number;
  images?: string[];
  video?: string;
  propertyTypeId: number;
  propertyOlxCategoryId: number;
  propertyImovelWebCategoryId?: number;
  addressZoneId?: number;
  status?: enPropertyStatus;
  disableSyncChavesNaMao?: boolean;
  disableSyncLead4Sales?: boolean;
  disableSyncImovelWeb?: boolean;
  disableSyncVivaReal?: boolean;
  disableSyncSiteIbs?: boolean;
  disableSyncSiteVision?: boolean;
  disableDuplication?: boolean;

  locations?: IPropertyLocation[];
  locationCount?: number;
  benefits?: IPropertyExtraBenefit[];
  propertyType?: IPropertyType;
  propertyOlxCategory: IPropertyOlxCategory;
  propertyImovelWebCategory: IPropertyImovelWebCategory;
  propertyOwnerId?: number;
  propertyOwner?: Partial<IPropertyOwner>;

  createdDate?: Date;
  updatedDate?: Date;
  deletedDate?: Date;
}

export interface IPropertyForm extends Pick<IProperty, Exclude<keyof IProperty, 'images'>> {
  mainAddress?: IAddress;
  benefitsIds?: number[];

  images?: Array<string | IImageReaderResult>;
}
